<template>
    <div>
        <nav style="padding-bottom:70px" aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
                <li :class="{'page-item':true}" v-if="!_pagination.prev_page_url">
                    <a class="page-link">Previous</a>
                </li>
                <li :class="{'page-item':true,'disabled':!_pagination.prev_page_url}" v-else @click.prevent="fetchNext(_pagination.prev_page_url)">
                    <a class="page-link"  href="#">Previous</a>
                </li>
                <li v-for="n in _pagination.last_page" :key="n" :class="{'page-item':true,'active':(_pagination.current_page == n)}"  @click.prevent="fetchNext(`${_pagination.path}?page=${n}`)"><a class="page-link" href="#">{{n}}</a></li>
                <li :class="{'page-item':true,'disabled':!_pagination.next_page_url}" v-if="!_pagination.next_page_url" >
                    <a class="page-link"  href="#">Next</a>
                </li>
                <li :class="{'page-item':true,'disabled':!_pagination.next_page_url}" v-else @click.prevent="fetchNext(_pagination.next_page_url)">
                    <a class="page-link"  href="#">Next</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        props: {
            pagination: {
                type: Object,
                required: true,
                default: () => { return {}}
            }
        },

        methods: {
            fetchNext(payload) {
                this.$emit('fetchNext',payload);
            }
        },
        computed: {
            _pagination() {
                return this.pagination
            }
        }

        
    }
</script>

<style lang="scss" scoped>

</style>