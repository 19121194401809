
export default{
    data() {
        return {
            apiDomain: process.env.VUE_APP_API_DOMAIN,
        }
    },
    methods:{
        
        
        makePagination(meta,links){
            
            var pagination =   {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next_page_url,
                prev_page_url: links.prev_page_url,
                path:meta.path
            }
            return { ...pagination}
        },

    }
}